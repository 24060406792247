import { Box, Link, LinkList, LinkListHeading, ListItem } from '@vp/swan'
import { PageSeoDescription, PageSeoTitle } from 'components/seo/page-seo.component'
import { PageProps } from 'gatsby'
import { UserProvider } from 'lib/user'
import { FC } from 'react'
import { IntlProvider } from 'react-intl'
import { PageContextType } from 'types/application.types'
import { noOp, usePostcardPRDCheck } from 'utilities/functions.utils'

import { UPLOAD_PAGE_MANUAL_ADDRESSING } from 'constants/feature-flags.constants'
import { useFeatureFlag } from 'hooks/use-feature-flag.hook'

const IndexPage: FC<PageProps<unknown, PageContextType>> = ({ pageContext }) => {
  const isPostcard = usePostcardPRDCheck()
  const aListType = isPostcard ? 'a mailing' : 'an address'
  const { featureToggle: manualAddressingFeatureToggle, loading: loadingManualAddressingFlag } = useFeatureFlag(UPLOAD_PAGE_MANUAL_ADDRESSING)

  const recipient = manualAddressingFeatureToggle ? 'recipient' : 'address'

  return (
    <IntlProvider messages={pageContext.messages} locale={pageContext.localeCulture} defaultLocale="en-US" onError={noOp}>
      <UserProvider localeCulture="en-US">
        <Box align="center" mt={10}>
          <PageSeoTitle content="AME services" />
          {!loadingManualAddressingFlag && (
            <PageSeoDescription content={`Upload ${aListType} list from your computer or choose a saved ${recipient} list to get started.`} />
          )}
          <LinkListHeading>Available pages</LinkListHeading>
          <LinkList>
            <ListItem>
              <Link to="en-us/ame/upload">EN - Upload</Link>
            </ListItem>
            <ListItem>
              <Link to="es-us/ame/upload">ES - Upload</Link>
            </ListItem>
            <ListItem>
              <Link to="/en-ca/ame/upload">EN-CA - Upload</Link>
            </ListItem>
            <ListItem>
              <>
                <Link to="/fr-ca/ame/upload">FR-CA - Upload</Link>
              </>
            </ListItem>
          </LinkList>
        </Box>
      </UserProvider>
    </IntlProvider>
  )
}
export default IndexPage
